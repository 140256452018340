@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');


body{
  background: #f4f3f2;  
  background: -webkit-linear-gradient#F59040;  
  background: linear-gradient#F59040; 
  
}

:root{
  --ProjectColor:hsl(290deg 85.19% 69.1%);
  --ProjectColorhover:rgb(134, 3, 134);
  --labelcolor :black;
  --projectwhite:white;
  --selectbackgroundcolor: rgba(223, 119, 209, 0.5);
  --fontsize: 12px;
}




.toast-container-over-header{
  /* background-color: aqua;
  width: 200px;
  height: 50px; */
  position: absolute;
  top: 20px;
  /* bottom: 0; */
  right: 10px;
  z-index:9999;
}
