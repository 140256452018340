#panel1bh-header{
    color: var(--labelcolor) !important;
    font-size: 19px !important;
    text-align: left;
    position: relative;
    z-index: 0;
    /* margin-bottom: 5px; */
}
.RegisFormcon{
    /* height: 40px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    flex-wrap: wrap;
    gap: 5px;
    row-gap: 10px;
    /* border-bottom: 1px solid var(--ProjectColor); */
}
.RegisFormcon1{
    justify-content: flex-start;
    gap: 10px;
}
.RegisForm_1{
    padding: 0px 5px;
    height: 100%;
    width: 295px;
    display: flex;
    align-items: center;
    color: grey;
    gap: 5px;
}
.RegisForm_1 label{
    width: 120px;
    display: flex;
    font-size: var(--fontsize);
    font-weight: bold;
    justify-content: space-between;
    text-align: start;
    align-items: center;

    gap: 10px;
    color: var(--labelcolor);
}


.RegisForm_1 input{
    width: 150px;
    background-color: transparent;
    border: 0px;
    outline: 0px;
    height: 18px;
    border: 1px solid var(--ProjectColor);
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;

}
.file-input-444 {
    display: flex;
    gap: 10px;
    align-items: center;
    /* position: relative; */
    margin-left: 7px;
  
  
  }
.RegisForm_1 select{
    width: 162px;
    background-color: transparent;
    border: 0px;
    outline: 0px;
    height: 30px;
   display: flex;
/* margin-bottom: 5px; */
    background-color: var(--selectbackgroundcolor);
}
.RegisterForm_2  {
    display: flex;
    align-items: center;
}
.RegisterForm_2 label{
    color: var(--labelcolor) !important;
    padding: 0px 5px;
}
.RegisterForm_2 label:hover{
    background-color: var(--ProjectColorhover) !important;
    color: var(--projectwhite) !important;
}

.RegisterForm_1_btns {
    display: flex;
    align-items: center ;
    justify-content: center !important;
    font-size: var(--fontsize);
    /* padding: 5px  !important; */
    text-align: center;
    height: 30px;
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    border: none;
    cursor: pointer;
    border-radius: 20px;
    width: 80px;
    font-weight: normal !important;


  }

  .Register_btn_con{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  /* Style the button on hover */
.RegisterForm_1_btns:hover {
    background-color:  var(--ProjectColorhover);
    color: var(--projectwhite);
}

.showcamera_takepic {
  
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
.sideopen_showcamera_takepic{
  position: fixed;
  top: 0;
  z-index: 3;
  left: 250px;
  width: calc(100% - 250px);
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
.showcamera_1_takepic1 ,.sideopen_showcamera_1_takepic1{
    background-color: #fff;
    width:75%;
    height: 77%;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    /* text-align: center; */
    position: relative;
    /* padding-top: 18px; */
}
  

#CapturedFile1,#CapturedFile2,
#CapturedFile3 {
  display: none; /* Hide the file inputs */
}

.pat-regis-fnt{
    font-size: var(--fontsize);
    color: var(--labelcolor);
}

.css-15v22id-MuiAccordionDetails-root{
    padding: 8px 16px 5px !important;
}

.css-o047l4-MuiTypography-root{
    gap: 5px !important;
}

.ad-het-vst{
    height: 50px !important;
}


.select_with_dwn{
    width: 180px;
}

.account_txtarea3{
    width: 50%;
    height: 30px;
    padding: 5px;

}


.choose_file_update{
    width: 70px !important;
    font-size: 10.8px !important;
    height: 24px !important;
}


@media screen and (max-width:1015px) {
    .RegisFormcon{
       
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        gap: 5px;
        row-gap: 10px;

    }
}


/* for procedure2pro.js */
@media screen and (max-width:600px) {
   

    .RegisForm_1234 label{
        width: 120px;
       
    
      }

      .RegisForm_1234 input{
        width: 100px;
     
    
    }

    .RegisForm_1234 select{
        width: 110px;
    }

    

}



/* @media screen and (max-width:1128px) {
    .RegisFormcon{
        height: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 0px;
    }
    .RegisForm_1{
        height: 40px;
  
    }
    .select_with_dwn{
        width: 50%;
    }
    .account_txtarea3{
        width: 50%;
        height: 30px;
        padding: 5px;
    
    }
}


@media screen and (max-width:350px) {
    .select_with_dwn{
        width: 90%;
    }
    .account_txtarea3{
        width: 80%;
        height: 30px;
        padding: 5px;
    
    }
} */