
.modal_edit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% ;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal_edit_1 {
    position: fixed;
    top: 0;
    left: 100;
    width: calc(100% - 250px);
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content-edit {
    background: rgb(255, 255, 255);
    width: 75%; 
    height:35vh;
    /* margin-top: 20px; */
    position: relative;
    top: 5px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto; /* Add vertical scrollbar if content overflows vertically */
    max-height: 90vh; /* Limit the maximum height to 90% of the viewport height */
    margin: 0 auto; /* Center the modal horizontally */
  }
  .modal-content-edit-1 {
    background: rgb(255, 255, 255);
    width: 98%; 
    height:75vh;
    /* margin-top: 20px; */
    position: relative;
    top: 5px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto; 
    max-height: 90vh; 
    margin: 0 auto; 
    overflow-x: hidden;
  }

 