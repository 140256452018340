
.head_img_head1 {
  width: 100%;
  height: 280px;

}
.head_img1 {
  position: relative;
  width: 100%;
  height: 213px !important;
  background-color: rgb(192, 190, 190);    
}

.head_img1 img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.savebtn123 {
  /* position: absolute; */
  margin-top: 20px;
  /* left: 47%;  */

  padding: 8px 20px;
  border-radius: 30px;
  background-color:  var(--ProjectColor);
  border: 1px solid  var(--ProjectColor);

}
.savebtn123:hover{
  background-color:  var(--ProjectColorhover);
  cursor: pointer;
}


.prof_round_head1 {
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: 75px;
  background-color: transparent;
}
.profile_round1 {
  width: 150px;
  height: 150px;
  background-color: rgb(240, 236, 236);
  border-radius: 50%;
}

.user_name1 {
  height: 75px;
  width: 75%;
  padding: 0px 15px;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 24px;
}
.rolenam1 h4{
  height: 6px;
  margin-top: 5px;


}
.forms1 {
  height: 60px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid  var(--ProjectColor);
  display: flex;
}
.user_items1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 15px;
}
.inp1 {
  border: transparent;

  outline: none;
  flex: 1;
}

.first2 {
  color:  var(--labelcolor);
  justify-content: flex-start;
  display: flex;
  
  font-size: 15px;
  width: 50%;
}
.pack2 {
  width: 100%;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
}
.pack2 input{
  width: 60%;
}
.pack1 {
  width: 350px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
}


@media ( max-width: 450px){
  .forms1 {
    flex-direction: column;
    height: auto;
    border: none;
   padding: 5px;
    width:100%;

 
  }
  .container_pass{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 
    
  }
  .user_items1{
    width: 260px;
 
    /* margin-top: 10px; */
    margin-bottom: 5px;
    display: flex; 
    flex-direction: column;
    padding: 5px;


  }
  .pack2 {
    width: 100%;
  
    display: flex;
    margin-top: 10px;
    border-bottom:1px solid  var(--ProjectColor);
}
.pack2 input ,.pack2 label{
  width: 100%;
  font-size: 13px;

}
.first2{
  width: 120px !important;
  margin-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  align-items: center;
 
}
.savebtn123 {
  /* position: absolute; */
  margin-top: 20px;
  /* left:35%;  */
  top: 85%;
  transform: none;
}
  .rolenam1 h4{
    margin-top: 20px;
    height: 5px;
    font-size: 20px;
  }
  .rolenam1 h6{
    font-size: 13px;
  }
 
}


@media ( min-width: 450px) and ( max-width: 768px) {
  .prof_round_head1 {
    display: flex;
    align-items: flex-end;
    position: relative;
    bottom: 75px;
    background-color: transparent;
  }
  .profile_round1 {
    width: 150px;
    height: 150px;
    background-color: rgb(240, 236, 236);
    border-radius: 50%;
  }
  .user_name1{
    width: 60%;
  }
  .head_img_head1 {
    width: 100%;

   
  }
  .head_img1{
    height: 213px !important;
  }

  .forms1 {
    flex-direction: column;
    height: auto;
    border: none;
    padding: 5px;
    width:100%;

 
  }
  .container_pass{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 
    
  }
  .user_items1{
    width: 260px;
    margin-bottom: 5px;
    display: flex; 
    flex-direction: column;
    padding: 5px;


  }
  .pack2 {
    width: 100%;
  
    display: flex;
    margin-top: 10px;
    border-bottom:1px solid  var(--ProjectColor);
}
.pack2 input ,.pack2 label{
  width: 100%;
  font-size: 13px;

}
.first2{
  width: 120px !important;
  margin-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  align-items: center;
 
}

.savebtn123 {
  /* position: absolute; */
  margin-top: 20px;
  /* left:45%;  */
  /* top: 85%; */
  transform: none;
}



 .rolenam1 h4{
  margin-top: 10px;
  height: 5px;
  font-size: 20px;
}
.rolenam1 h6{
  font-size: 13px;
}

}


@media (  min-width: 769px  ) and (max-width:1024px){


 .savebtn123 {
  /* position: absolute; */
  margin-top: 20px;
  /* left: 45%;  */
  /* transform: none; */
 }

}
