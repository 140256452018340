/* css for billingInvoice and ImgPreview */
.billing {
    padding: 10px;
      /* margin-top: 35px; */

  }
  
  .h_head {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 5px;
    color: var(--labelcolor);
    background-color: var(--ProjectColor);
  }
  .img_section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    margin-left: 10%;
    margin-top: 20px;
  }
  
  .img_1 {
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
  }
  
  .img_1 input {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .img_2 {
    height: 200px;
    width: 200px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .img_2 img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
  .img_btn {
    margin-top: 20px;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_btn button {
    width: 120px;
  
  }
  .inp_1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(33.33% - 15px);
    margin-bottom: 15px;
    border-bottom: 1px solid var(--ProjectColor);
    width: 280px;
  }
  
  .inp_1 input {
  
    outline: none;
    border: none;
    background-color: transparent;
    padding: 10px 5px;
    color: var(--labelcolor);
  }
  .inp_1 label {
    color: var(--labelcolor);
    width: 120px;

  }
  .inp_1 input::placeholder {
    color: rgb(151,150,150);
    padding: 12px 5px;
  }




  .btn_1 {
    border: none;
    background-color: var(--ProjectColor);
    color: var(--labelcolor);
    cursor: pointer;
    width: 100px;
    border-radius: 25px;
    height: 35px;
  }
  .img_btn {
    margin-top: 20px;
    gap: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img_btn button {
    width: 120px;
  
  }

  .acct_choose{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    left: 1px !important;
    width: 30px !important;
  }
 
  @media (max-width: 768px)  {
    .img_section {
      display: flex;
      flex-direction: column;
      overflow-x:hidden ;
      margin-left: 10px;
    }
  }